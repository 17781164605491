<template>
  <Layout :pagetitle="'Company'">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title mb-0">Cadastro da Empresa</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-6">
                <label class="form-label" for="name-input">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Nome da empresa"
                  id="name-input"
                  v-model="company.name"
                />
              </div>

              <div class="col-12 col-lg-6 mt-3 mt-lg-0">
                <label class="form-label" for="slugname-input">Slug</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Slug que é exibido na barra de endereços do navegador"
                  id="slugname-input"
                  v-model="company.slugName"
                  @input="company.slugName = $utils.normalizeName(company.slugName)"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-12 mt-3">
                <label class="form-label" for="message-input">Mensagem</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mensagem que será exibida na página principal"
                  id="message-input"
                  v-model="company.message"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-12 mt-3">
                <label class="form-label">Selecione a cor do tema</label>
                <div class="d-flex">
                  <div
                    :class="`color-selector bg-primary cursor-pointer mx-1 ${company.color == 'primary' ? 'color-selected' : ''}`"
                    @click="setColor('primary')"
                  ></div>

                  <div
                    :class="`color-selector bg-secondary cursor-pointer mx-1 ${company.color == 'secondary' ? 'color-selected' : ''}`"
                    @click="setColor('secondary')"
                  ></div>

                  <div
                    :class="`color-selector bg-success cursor-pointer mx-1 ${company.color == 'success' ? 'color-selected' : ''}`"
                    @click="setColor('success')"
                  ></div>

                  <div
                    :class="`color-selector bg-info cursor-pointer mx-1 ${company.color == 'info' ? 'color-selected' : ''}`"
                    @click="setColor('info')"
                  ></div>

                  <div
                    :class="`color-selector bg-warning cursor-pointer mx-1 ${company.color == 'warning' ? 'color-selected' : ''}`"
                    @click="setColor('warning')"
                  ></div>

                  <div
                    :class="`color-selector bg-danger cursor-pointer mx-1 ${company.color == 'danger' ? 'color-selected' : ''}`"
                    @click="setColor('danger')"
                  ></div>

                  <div
                    :class="`color-selector bg-pink cursor-pointer mx-1 ${company.color == 'pink' ? 'color-selected' : ''}`"
                    @click="setColor('pink')"
                  ></div>

                  <div
                    :class="`color-selector bg-light cursor-pointer mx-1 ${company.color == 'light' ? 'color-selected' : ''}`"
                    @click="setColor('light')"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title mb-0">Logomarca telas grandes</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-6" v-if="company?.imageUrl">
                <label class="form-label mt-lg-2">Imagem Atual</label>
                <div class="logo-lg mt-1">
                  <img
                    :src="company?.imageUrl ? company.imageUrl : ''"
                    alt="Logo"
                    height="80"
                    width="300"
                  />
                </div>
              </div>

              <div class="col-12 col-lg-6 mt-4 mt-lg-0">
                <input
                  class="form-control"
                  @change="handleImage('image')"
                  type="file"
                  ref="imageInput"
                  accept="image/jpeg, image/jpg, image/png"
                />
                <div class="logo-lg mt-3" v-if="image">
                  <img
                    :src="imagePreview"
                    alt="Novo Logo"
                    height="80"
                    width="300"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title mb-0">Logomarca telas pequenas</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-6" v-if="company?.imageMobileUrl">
                <label class="form-label mt-lg-2">Imagem Atual</label>
                <div class="logo-lg mt-1">
                  <img
                    :src="company?.imageMobileUrl ? company.imageMobileUrl : ''"
                    alt="Logo"
                    height="80"
                    width="100"
                  />
                </div>
              </div>

              <div class="col-12 col-lg-6 mt-4 mt-lg-0">
                <input
                  class="form-control"
                  @change="handleImage('imageMobile')"
                  type="file"
                  ref="imageMobileInput"
                  accept="image/jpeg, image/jpg, image/png"
                />
                <div class="logo-lg mt-3" v-if="imageMobile">
                  <img
                    :src="imageMobilePreview"
                    alt="Novo Logo Mobile"
                    height="80"
                    width="100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div
        v-if="isLoading"
        :class="`spinner-border m-1 text-${initColor ? initColor : 'primary'}`"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else class="d-flex justify-content-end">
        <button
          :class="`btn btn-rounded waves-effect waves-light col-6 col-md-2 btn-${initColor ? initColor : 'primary'}`"
          @click="$router.push({ name: 'companyPrincipal' })"
        >
          <span>Voltar</span>
        </button>
        <button
          :class="`btn btn-rounded waves-effect waves-light col-6 col-md-2 mx-lg-2 btn-${initColor ? initColor : 'primary'}`"
          @click="onSave()"
        >
          <span>Salvar</span>
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      company: {},
      image: null,
      imagePreview: null,
      imageMobile: null,
      imageMobilePreview: null,
      initColor: null,
      initSlug: null,
    };
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState(["isLoading"]),
  },
  created() {
    this.company = JSON.parse(localStorage.getItem("company"));
    this.initColor = this.company.color;
    this.initSlug = this.company.slugName;
  },
  methods: {
    ...mapActions(["setLoading"]),
    setColor(color) {
      this.company.color = color;
    },
    handleImage(imageRef) {
      const fileInputRef = `${imageRef}Input`;
      this[imageRef] = this.$refs[fileInputRef].files[0];

      if (this[imageRef]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this[`${imageRef}Preview`] = e.target.result;
        };
        reader.readAsDataURL(this[imageRef]);
      } else {
        this[`${imageRef}Preview`] = null;
      }
    },
    async slugExists(slug) {
      const company = await this.$utils.getCompanyBySlug(slug);
      if (company) {
        return true;
      } else {
        return false;
      }
    },
    onSave() {
      if (!this.company.name || this.company.name.length < 3) {
        this.$customAlert({
          type: "warning",
          msg: "Informe um nome",
        });
        return;
      }

      if (!this.company.slugName || this.company.slugName.length < 3) {
        this.$customAlert({
          type: "warning",
          msg: "Informe um slug",
        });
        return;
      }

      if (
        this.company.slugName != this.initSlug &&
        this.slugExists(this.company.slugName)
      ) {
        this.$customAlert({
          type: "warning",
          msg: "Este slug já existe, informe um slug diferente",
        });
        return;
      }

      const formData = new FormData();
      formData.append("name", this.company.name);
      formData.append("slugName", this.company.slugName);
      formData.append("color", this.company.color);
      formData.append("message", this.company.message);
      if (this.image) {
        formData.append("image", this.image);
      }
      if (this.imageMobile) {
        formData.append("imageMobile", this.imageMobile);
      }

      const header = {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      };

      this.setLoading(true);
      this.$api
        .put(`company/${this.company._id}`, formData, header)
        .then((res) => {
          this.setLoading(false);
          localStorage.setItem("company", JSON.stringify(res.data));
          this.$router.push({ name: "companyPrincipal" });
        })
        .catch((err) => {
          this.setLoading(false);
          this.$customAlert({
            type: "error",
            msg: err?.data?.message || "Erro ao atualizar empresa",
          });
        });
    },
  },
};
</script>

<style scoped>
.color-selector {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.color-selected {
  border: 3px solid #ababab;
}

.image-container {
  max-width: 300px;
  max-height: 100px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
